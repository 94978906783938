import React from "react";
import Image from "next/image";
import IconBannerPattern from "components/Icons/IconBannerPattern";
import classNames from "lib/classNames";

export default function Header({
  variant = "institution",
  themes,
  title = null,
}) {
  const theme = themes.filter((theme) => theme.field_variant === variant)[0];

  const variants = {
    default: "bg-default text-white",
    contact: "bg-default text-white",
    primary: "bg-primary text-white",
    pastorale: "bg-primary text-white",
    institution: "bg-institution text-white",
    formation: "bg-formation text-default-dark",
    internship: "bg-internship text-white",
    news: "bg-news text-white",
    inscription: "bg-inscription text-white",
  };

  return (
    <section className="relative overflow-hidden" aria-hidden="true">
      <div className="aspect-w-16 aspect-h-14 md:aspect-h-7 xl:aspect-h-5 bg-default-lighter">
        <div className="flex flex-col justify-end">
          {theme && theme.field_image ? (
            <Image
              src={theme.field_image.image_style_uri.hq}
              {...theme.field_image.resourceIdObjMeta}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              className="opacity-70"
            />
          ) : (
            <Image
              src="/images/banner.jpg"
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              className="opacity-70"
            />
          )}
          <IconBannerPattern className="absolute bottom-0 right-0 -mb-px w-[34.3vw] h-[11.45vw] text-white opacity-40" />
          <div className="container px-4 py-16 mx-auto">
            <div className="relative text-center md:text-left">
              <div className="md:pl-16 md:pt-16">
                <div
                  className={classNames(
                    "inline-block px-4 md:px-10 py-4 bg-opacity-80 font-important font-bold text-4xl xl:text-5xl 2xl:text-6xl text-white uppercase",
                    variants[variant]
                  )}
                >
                  {title || theme?.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
